import api from "../../index";

export const GetOrganizerTypeListApi = () => api.post('organizer-typelist');
export const CreateOrganizerApi = (data) => api.post('create-clientorganizer', data);
export const GetSectionListApi = (data) => api.post('client-sectionList', data);
export const GetSectionWiseQuestionApi = (data) => api.post('client-organizer-quelist', data);
export const GetOrganizerListApi = (data) => api.post('clientorganizerlist', data);
export const SubmitOrganizerApi = (data) => api.post('organizer-submit', data);
export const SetActiveTaskApi = (data) => api.post('run-task', data);
export const RepeatSectionApi = (data) => api.post('create-clientsection', data);
export const RemoveSectionApi = (data) => api.post('delete-organizer-section', data);
