import React, { useEffect, useState } from 'react';
import './Referrals.scss';
import SkeletonTable from '../../components/table/SkeletonTable.jsx';
import { Avatar, Form, Input } from 'antd';
import columns from '../../columns/referrals/referrals-user.js';
import ModalComponent from '../../components/modals/ModalComponent.jsx';
import LoadableButton from '../../components/buttons/LoadableButton.jsx';
import { AddReferralApi, GetReferralDetailsApi } from '../../api/request/referral.js';
import toast from 'react-hot-toast';
import { use } from 'framer-motion/client';
const { Search } = Input;

export default function Referrals() {
  const [form] = Form.useForm();
  const [isAdd, setIsAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [referralData, setReferralData] = useState([]);
  const [totalReferral, setTotalReferral] = useState('');
  const [totalRegistered, setTotalRegistered] = useState('');
  const [paidReferral, setPaidReferral] = useState('');
  const [referralBonus, setReferralBonus] = useState('');
  const [isSpin, setIsSpin] = useState(false);
  const [initialValues, setInitialValues] = useState({ name: "", email: "", mobile_no: "" });

  const referralCards = [
    { total: totalReferral || '--', title: 'My Referrals', icon: '/assets/icons/myRefferal.svg', bgColor: '#E9E7FD' },
    { total: totalRegistered || '--', title: 'Registered', icon: '/assets/icons/registerRefferal.svg', bgColor: '#FBEAEA' },
    { total: paidReferral || '--', title: 'Paid Referrals', icon: '/assets/icons/paidRefferal.svg', bgColor: '#DDF6E8' },
    { total: `$ ${referralBonus || 0}`, title: 'Referrals Bonus', icon: '/assets/icons/bounsRefferal.svg', bgColor: '#E9E7FD' },
  ];

  const getReferralDetails = async () => {
    try {
      setIsSpin(true);
      const { data } = await GetReferralDetailsApi();
      if (data?.status) {
        setTotalReferral(data?.total_referral);
        setTotalRegistered(data?.total_registered);
        setPaidReferral(data?.paid_referral);
        setReferralBonus(data?.referral_bonus);
        setReferralData(data?.data.map((item) => {
          return {
            ...item,
            name: (<div className='flex items-center gap-3'>
              <Avatar size='large' src='https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=2238' />
              <p>{item?.name}</p>
            </div>),
            status: (
              item?.status === 'registered' ? <p className='font-medium text-green-500'>{item?.status}</p> :
                item?.status === 'unregistered' ? <p className='font-medium text-red-500'>{item?.status}</p> :
                  <p className='font-medium text-yellow-500'>{item?.status}</p>
            ),
          }
        })
        );
      }
      setIsSpin(false);
    }
    catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        setIsSpin(false);
      }
    }
  }

  const hadleAddReffal = async (values) => {
    try {
      setIsLoading(true);
      const { data } = await AddReferralApi(values);
      if (data?.status) {
        toast.success(data?.message);
        form.resetFields();
        setIsAdd(false);
        getReferralDetails();
      }
      setIsLoading(false);
    }
    catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getReferralDetails();
  }, [])

  return (
    <div>
      <div className='grid xl:grid-cols-3 gap-5'>
        <div className='xl:col-span-2'>
          <div className='referrals-bg border-2 rounded-2xl p-8 flex items-center'>
            <div className='md:w-1/2'>
              <p className='font-bold text-2xl'>Refer a friend</p>
              <p className='text-secondaryLight1 mt-4'>Add project data, create thematic pages, edit data, share information with team members</p>
              <div className='flex items-center justify-center space-x-3 mt-5'>
                <Search
                  value="untitledui.com/invite/8ah6Gvs"
                  enterButton="COPY"
                  size="large"
                  className='border-2 rounded-lg border-primary col-span-2'
                />
                <button onClick={e => setIsAdd(true)} className='bg-primary text-white flex items-center justify-center px-4 py-2.5 rounded-lg'>
                  <img src="/assets/icons/add.svg" alt="icon" className='me-1 h-5 w-5 object-contain' />
                  <span>Add</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-5'>
          {referralCards.map((item, index) => (
            <div key={index} className=' border-2 rounded-2xl flex justify-around items-center'>
              <div className='space-y-3'>
                <div className={`bg-[${item.bgColor}] rounded-xl size-10 flex items-center justify-center`}>
                  <img src={item.icon} alt='icon' className='object-contain w-6 h-6' />
                </div>
                <p className='text-base'>{item.title}</p>
                <p className='font-bold text-2xl mt-1'>{item.total}</p>
              </div>
              <div></div>
            </div>
          ))}
        </div>
      </div>

      <div className='border-2 rounded-xl my-5'>
        <p className='my-5 mx-5 font-semibold text-2xl'>Referred Users</p>
        <div className='my-6 referral-table'>
          <SkeletonTable columns={columns} data={referralData} isLoading={isSpin}/>
        </div>
      </div>

      <ModalComponent isOpen={isAdd} setIsOpen={setIsAdd} title='Refer & Earn'>
        <div className='px-5'>
          <Form autoComplete='off' form={form} initialValues={initialValues} onFinish={hadleAddReffal}>

            <Form.Item name="name" rules={[{ required: true, message: 'Please enter name!', },]}>
              <Input placeholder='Enter referral name...' size='large' />
            </Form.Item>
            <Form.Item name="email" rules={[
              { required: true, type: 'email', message: 'Please enter email!' }
            ]}>
              <Input placeholder='Enter referral email...' size='large' />
            </Form.Item>
            <Form.Item name="mobile_no" rules={[
              { required: true, message: 'Please enter phone No.!', },
            ]}>
              <Input size="large" type='Number' placeholder="Enter referral phone..." />
            </Form.Item>
            <div className='flex justify-center '>
              <LoadableButton
                className='bg-primary text-white uppercase text-base font-semibold px-5 py-2 rounded-lg mt-7 '
                type='submit'
                lable='Submit'
                loadingLable='Loading...'
                isLoading={isLoading}
              />
            </div>
          </Form>
        </div>
      </ModalComponent>
    </div>
  )
}
