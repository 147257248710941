import React from 'react';
import MyInput from './formElements/Input.jsx';
import MyDatePicker from './formElements/DatePicker.jsx';
import MyRadio from './formElements/Radio.jsx';
import MyCheckBox from './formElements/CheckBox.jsx';
import MyEmail from './formElements/Email.jsx';
import MyNumber from './formElements/Number.jsx';
import MyFile from './formElements/File.jsx';
import MyDropdown from './formElements/Dropdown.jsx';
import YesNo from './formElements/YesNo.jsx';

export default function QuestionContainer({ questions, reloadSections, reloadQuestions }) {

  const QuestionRender = (question) => {
    switch (question?.question?.field_type) {
      case 'input':
        return <MyInput data={question} />
      case 'email':
        return <MyEmail data={question} />
      case 'number':
        return <MyNumber data={question} />
      case 'date':
        return <MyDatePicker data={question} />
      case 'radio':
        return <MyRadio data={question} reloadSections={reloadSections} reloadQuestions={reloadQuestions} />
      case 'checkbox':
        return <MyCheckBox data={question} reloadSections={reloadSections} reloadQuestions={reloadQuestions} />
      case 'dropdown':
        return <MyDropdown data={question} reloadSections={reloadSections} reloadQuestions={reloadQuestions} />
      case 'yes-no':
        return <YesNo data={question} reloadSections={reloadSections} reloadQuestions={reloadQuestions} />
      case 'file':
        return <MyFile data={question} />
      default:
    }
  }

  return (
    <div>
      {
        questions?.map((question) => (
          QuestionRender(question)
        ))
      }
    </div>
  )
}
