import api from "../../index";

export const GetFolderListApi = () => api.post('filefolderlist');
export const GetChildFolderListApi = (data) => api.post('viewfilefolder', data);
export const DeleteFileFolderApi = (data) => api.post('deletefilefolder', data);
export const UploadFileFolderApi = (data, headers) => api.post('uploadfilefolder', data, headers);
export const GetTrashFolderListApi = () => api.post('trashdocumentslist');
export const OpenTrashFolderApi = (data) => api.post('viewtrashfilefolder', data);
export const PermanentDeleteFileFolderApi = (data) => api.post('permanentdeletefilefolder', data);
export const RestorefilefolderApi = (data) => api.post('restorefilefolder', data);
export const MoveFileFolderApi = (data) => api.post('movefilefolder', data);
export const DownloadFileFolderApi = (data) => api.post('download-file-folder', data);