import React, { useEffect, useState } from 'react';
import ResetPassword from './components/ResetPassword';
import TFAuthentication from './components/TFAuthentication';
import Notification from './components/Notification';
import DownloadApp from './components/DownloadApp';
import { Avatar, Form, Input, Upload, Image } from 'antd';
import { useSelector } from 'react-redux';
import { GetSettingsDetailsApi, UpdateProfileApi } from '../../api/request/settings/settings';
import ModalComponent from '../../components/modals/ModalComponent';
import LoadableButton from '../../components/buttons/LoadableButton';
import toast from 'react-hot-toast';
import { setUserDetails } from "../../store/Slices/userSlice.js";
import { useDispatch } from 'react-redux';

export default function Settings() {

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.user.userDetails);
    const [isLoading, setIsLoading] = useState(false);
    const [settingsData, setSettingsData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({ first_name: '', last_name: '', profile_img: '' });
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [isFormChanged, setIsFormChanged] = useState(false);

    const handleFieldsChange = (_, allFields) => {
        const isChanged = allFields.some(field => field.touched);
        setIsFormChanged(isChanged);
    };

    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        form.setFieldsValue({ profile_img: newFileList });
        if (newFileList?.length == 0) {
            form.setFieldsValue({ profile_img: [] });
        }
    }

    const UploadButton = (
        <button type="button" className='flex flex-col items-center gap-2'>
            <div className="size-7"><img src="/assets/icons/upload.svg" alt="icon" /></div>
            Upload
        </button>
    );

    const getSettingsDetails = async () => {
        try {
            setIsLoading(true);
            const { data } = await GetSettingsDetailsApi();
            if (data?.status) {
                if (data?.data?.image) {
                    setFileList([{ uid: '1', url: data?.data?.image, status: 'done' }]);
                }
                setInitialValues({ first_name: data?.data?.first_name, last_name: data?.data?.last_name, profile_img: data?.data?.image });
                setSettingsData(data?.data);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getSettingsDetails();
    }, []);

    const profileUpdate = async (values) => {
        const extractOriginFileObjs = (fileArray) => {
            if (fileArray.map(file => file.originFileObj)) {
                return fileArray.map(file => file.originFileObj);
            } else {
                return null;
            }
        };
        setIsProfileLoading(true);
        try {
            let formData = new FormData();
            formData.append("first_name", values?.first_name);
            formData.append("last_name", values?.last_name);
            if (Array.isArray(values?.profile_img)) {
                extractOriginFileObjs(values?.profile_img)?.map((file) => {
                    if (file) {
                        formData.append("profile_img", file);
                    }
                });
            }
            let apiHeader = { headers: { Accept: "application/json", "Content-Type": "multipart/form-data" } };
            const { data } = await UpdateProfileApi(formData, apiHeader);
            if (data?.status) {
                dispatch(setUserDetails({ ...userDetails, first_name: data?.data?.first_name, last_name: data?.data?.last_name, name: data?.data?.name, image: data?.data?.image }));
                toast.success(data?.message);
                setInitialValues({ first_name: data?.data?.first_name, last_name: data?.data?.last_name, profile_img: data?.data?.image });
                if (data?.data?.image) {
                    setFileList([{ uid: '1', url: data?.data?.image, status: 'done' }]);
                }
                setIsModalOpen(false);
            }
            setIsProfileLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsProfileLoading(false);
        }
    }

    useEffect(() => {
        if (!isModalOpen) {
            form?.resetFields();
        }
    }, [isModalOpen]);

    return (
        <div className='border-2 rounded-xl p-4'>
            <div className='mb-6'>
                <p className='text-lg font-semibold'>Account Details</p>
                <div className='mt-4 flex items-center gap-6'>
                    <div className='relative'>
                        <Avatar size='large' className='uppercase size-32 text-4xl' src={userDetails?.image}>{(userDetails?.first_name)?.charAt(0)}{(userDetails?.last_name)?.charAt(0)}</Avatar>
                        <button className='bg-primary p-2 rounded-full cursor-pointer absolute bottom-1 right-2' onClick={() => setIsModalOpen(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="size-5">
                                <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                            </svg>
                        </button>
                    </div>
                    <p className='text-lg font-medium'>{userDetails?.name}</p>
                </div>
            </div>
            <div className='grid lg:grid-cols-2 gap-5'>
                {/* <ProfileAccess /> */}
                <Notification isLoading={isLoading} settingsData={settingsData} setSettingsData={setSettingsData} />
                <TFAuthentication isLoading={isLoading} settingsData={settingsData} setSettingsData={setSettingsData} />
                <ResetPassword />
                <DownloadApp />
            </div>

            {/* -------- Profile modal -------- */}
            <ModalComponent isOpen={isModalOpen} setIsOpen={setIsModalOpen} title='Update Profile' width={600}>
                <Form form={form} autoComplete='off' initialValues={initialValues} onFinish={profileUpdate} onFieldsChange={handleFieldsChange}>
                    <div>
                        <Form.Item name="profile_img" rules={[{ required: true, message: 'Please upload profile picture.' }]}>
                            <Upload
                                beforeUpload={() => false}
                                multiple
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                                {fileList.length >= 1 ? null : UploadButton}
                            </Upload>
                        </Form.Item>
                        {previewImage && (
                            <Image
                                wrapperStyle={{ display: 'none' }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                            />
                        )}
                    </div>
                    <div>
                        <label className='text-base font-medium'>First Name</label>
                        <Form.Item name="first_name" className='mb-0' rules={[{ required: true, message: 'Please enter first name.' }]}>
                            <Input placeholder="First name" className="mt-1 w-full ps-4 py-2" />
                        </Form.Item>
                    </div>
                    <div className='mt-3'>
                        <label className='text-base font-medium'>Last Name</label>
                        <Form.Item name="last_name" className='mb-0' rules={[{ required: true, message: 'Please enter last name.' }]}>
                            <Input placeholder="Last name" className="mt-1 w-full ps-4 py-2" />
                        </Form.Item>
                    </div>
                    <LoadableButton
                        className='bg-primary text-white uppercase text-base font-semibold py-2 rounded-lg px-8 mt-5'
                        type="submit"
                        lable='Update'
                        disabled={!isFormChanged || isProfileLoading}
                        isLoading={isProfileLoading}
                        loadingLable='Updating profile...'
                    />
                </Form>
            </ModalComponent>
        </div>
    )
}