import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import ModalComponent from '../../../components/modals/ModalComponent';
import LoadableButton from '../../../components/buttons/LoadableButton';
import toast from 'react-hot-toast';
import { ChangePasswordApi } from '../../../api/request/settings/settings';

export default function ResetPassword() {
    const [form] = Form.useForm();

    const [isResetPassword, setIsResetPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({ old_password: '', new_password: '', confirm_password: '' });
    const [passwordVisible, setPasswordVisible] = useState(true);

    const handleSubmit = async (values) => {
        setIsLoading(true);
        delete values?.confirm_password;
        try {
            const { data } = await ChangePasswordApi(values);
            if (data?.status) {
                toast.success(data?.message);
                setIsResetPassword(false);
            }
            else{
                toast.error(data?.message);
            }
            setIsLoading(false);
        }
        catch (error) {
            toast.error(error?.response?.data?.message);
            setIsLoading(false);
        }
    }
    useEffect(() => {
        if (!isResetPassword) {
            form.resetFields();
            setInitialValues({ old_password: '', new_password: '', confirm_password: '' });
        }
    }, [isResetPassword])

    return (
        <div>
            <div className='border-2 rounded-lg p-4'>
                <p className='font-semibold text-lg border-b-2 pb-3'>Reset Password?</p>
                <div className='mt-4'>
                    <p>You’re about to send password reset instructions to test@gmail.com! Are you sure?</p>
                    <Button size='large' type='primary' className='p-4 uppercase font-semibold mt-4' onClick={e => setIsResetPassword(true)}>Reset password</Button>
                </div>
            </div>
            <ModalComponent isOpen={isResetPassword} setIsOpen={setIsResetPassword} title='Reset Password' width={450} >
                <h4 className='text-base font-medium'>Please fill in the fields below to proceed with updating your password</h4>
                <Form autoComplete='off' form={form} initialValues={initialValues} onFinish={handleSubmit}>
                    <Form.Item name="old_password" className='mb-0 mt-5' rules={[
                        { required: true, message: 'Please enter your current password!', },
                    ]}>
                        <Input.Password placeholder='current password' size='large' />
                    </Form.Item>
                    <Form.Item name="new_password" className='mb-0 mt-5' rules={[
                        { required: true, message: 'Please enter your new password!', },
                        {
                            pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                            message: 'Please enter strong password.',
                        },
                    ]}>
                        <Input.Password placeholder='New password' size='large' visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}/>
                    </Form.Item>
                    <Form.Item name="confirm_password" className='mb-0 mt-5' rules={[
                        { required: true, message: 'Please enter your confirm password!', },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('new_password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                        }),
                    ]}>
                        <Input.Password placeholder='Confirm password' size='large' />
                    </Form.Item>
                    <LoadableButton
                        className='bg-primary text-white uppercase text-base font-semibold w-full py-2 rounded-lg mt-7 mb-5'
                        type='submit'
                        lable='Change password'
                        loadingLable='UpDating password...'
                        isLoading={isLoading}
                    />
                </Form>
            </ModalComponent>
        </div>
    )
}