import CryptoJS from "crypto-js";

export const encryptData = (data) => {
    var message = JSON.stringify(data);
    var key = process.env.REACT_APP_SECRET_KEY;
    var encrypted = CryptoJS.AES.encrypt(message, key);
    var safeCiphertext = encrypted.toString()
        .replace('+', 'xMl3Jk')
        .replace('/', 'Por21Ld')
        .replace('=', 'Ml32');
    return safeCiphertext?.toString();
};