import { Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import ModalComponent from '../../../components/modals/ModalComponent';
import { GetOrganizerTypeListApi, CreateOrganizerApi } from '../../../api/request/organizer/organizer.js';
import LoadableButton from '../../../components/buttons/LoadableButton';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export default function CreateOrganizer() {

    const navigate = useNavigate();
    const [organizer, setOrganizer] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isCreateLoading, setIsCreateLoading] = useState(false);
    const [isIncorporationModalOpen, setIsIncorporationModalOpen] = useState(false);
    const [selectedIncorporationOptions, setSelectedIncorporationOptions] = useState('');
    const [incorporationOptions, setIncorporationOptions] = useState([
        { name: 'Federal', icon: '/assets/icons/federal.svg' },
        { name: 'Ontario', icon: '/assets/icons/ontario.svg' },
        { name: 'British Columbia', icon: '/assets/icons/british-columbia.svg' },
        { name: 'Alberta', icon: '/assets/icons/alberta.svg' },
        { name: 'Business Trade ', icon: '/assets/icons/business-trade.svg' },
    ]);

    const onChange = (value) => {
        setSelectedValue(value);
        // setIsIncorporationModalOpen(true);
    }

    const onCancel = () => {
        setSelectedValue('');
    }

    const getOrganizerList = async () => {
        try {
            setIsLoading(true);
            const { data } = await GetOrganizerTypeListApi();
            if (data?.status) {
                setOrganizer(data?.data);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOrganizerList();
    }, []);

    const createOrganizerHandler = async () => {
        try {
            setIsCreateLoading(true);
            let params = {
                org_type_id: selectedValue?.id
            }
            const {data} = await CreateOrganizerApi(params);
            if (data?.status) {
                toast.success(data?.message);
                navigate(`/organizer/${data?.data?.id}`);
            }
            setIsCreateLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsCreateLoading(false);
        }
    }

    return (
        <div>
            <div>
                <p className='mb-3 font-semibold text-lg'>Create Organizer</p>
                {
                    isLoading ? (
                        <div className='flex flex-col items-center gap-2 mt-16'>
                            <Spin />
                            <p className='text-secondaryLight1'>Loading...</p>
                        </div>
                    ) : (
                        <div>
                            <div className='w-full flex flex-wrap gap-5'>
                                {
                                    organizer?.map((item, index) => (
                                        <div
                                            key={index}
                                            className={`py-3 px-12 font-medium border-2 rounded-md cursor-pointer duration-500 ${selectedValue?.id === item?.id ? 'text-primary border-primary bg-primaryLight' : 'text-secondaryLight1'}`}
                                            onClick={() => onChange(item)}
                                        >
                                            {item?.organizer_name}
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='mt-10 mb-6'>
                                <div className='flex items-center gap-4'>
                                    <LoadableButton
                                        className='bg-primary text-white font-medium uppercase px-8 py-2 rounded-lg themeHover duration-500'
                                        isLoading={isCreateLoading}
                                        onClick={createOrganizerHandler}
                                        lable='Create'
                                        loadingLable='Creating organizer...'
                                        disabled={!selectedValue}
                                    />
                                    <Button size='large' type='primary' ghost className='py-4 px-8 uppercase font-semibold' onClick={() => navigate('/organizer')}>Cancel</Button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>

            <ModalComponent isOpen={isIncorporationModalOpen} setIsOpen={setIsIncorporationModalOpen} title='Types of Incorparation' onCancel={onCancel}>
                <div className='grid grid-cols-2 sm:grid-cols-3 gap-5'>
                    {
                        incorporationOptions?.map((option, index) => (
                            <div
                                key={index}
                                className={`p-3 font-medium border-2 rounded-md cursor-pointer duration-500 ${selectedIncorporationOptions === option?.name ? 'text-primary border-primary bg-primaryLight' : 'text-secondaryLight1'}`}
                                onClick={() => setSelectedIncorporationOptions(option?.name)}
                            >
                                <div className={`mx-auto flex items-center justify-center size-9 rounded-md ${selectedIncorporationOptions === option?.name ? '' : 'bg-primaryLight'}`}>
                                    <img src={option?.icon} alt='icon' />
                                </div>
                                <p className='text-center mt-3'>{option?.name}</p>
                            </div>
                        ))
                    }
                </div>
                <div className='flex justify-center mt-6'>
                    <Button size='large' type='primary' className='py-4 px-8 uppercase font-semibold' onClick={() => setIsIncorporationModalOpen(false)}>
                        Next
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                        </svg>
                    </Button>
                </div>
            </ModalComponent>
        </div>
    )
}