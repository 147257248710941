import { Avatar, Button, Spin, Timeline } from 'antd';
import React, { useEffect, useState } from 'react';
import QuestionContainer from '../../../components/questionContainer/QuestionContainer.jsx';
import { GetSectionListApi, GetSectionWiseQuestionApi, SubmitOrganizerApi, SetActiveTaskApi, RepeatSectionApi, RemoveSectionApi } from '../../../api/request/organizer/organizer.js';
import { useNavigate, useParams } from 'react-router-dom';
import { getSocket } from '../../../services/Socket.js';
import LoadableButton from '../../../components/buttons/LoadableButton.jsx';
import toast from 'react-hot-toast';

export default function QuestionAnswerFlow() {

    const { id } = useParams();
    const socket = getSocket();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [organizer, setOrganizer] = useState([]);
    const [selectedSection, setSelectedSection] = useState(null);
    const [isQuestionLoading, setIsQuestionLoading] = useState(false);
    const [sectionList, setSectionList] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [isCompleted, setIsCompleted] = useState(true);
    const [isNext, setIsNext] = useState(true);
    const [isAllowSectionRepeat, setIsAllowSectionRepeat] = useState(false);
    const [isLoadingRepeat, setIsLoadingRepeat] = useState(false);
    const [isLoadingRemoveSection, setIsLoadingRemoveSection] = useState(false);

    useEffect(() => {
        socket.on('updatedAnswer', (response) => {
            setIsCompleted(response);
        });

        return () => {
            socket.off('updatedAnswer');
        };
    }, []);

    const getOrganizerDetails = async (isLoad = true) => {
        try {
            if (isLoad) {
                setIsLoading(true);
            }
            const { data } = await GetSectionListApi({ organizer_id: id });
            if (data?.status) {
                setOrganizer(data?.organizer_details);
                setSectionList(data?.data);
                if (data?.data?.length > 1) {
                    setIsNext(true);
                } else {
                    setIsNext(false);
                }
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOrganizerDetails();
    }, [id]);

    const getSectionWiseQuestion = async (isLoad = true) => {
        if (!selectedSection) {
            return;
        }
        try {
            if (isLoad) {
                setIsQuestionLoading(true);
            }
            let params = {
                organizer_id: id,
                section_id: selectedSection?.section_id
            }
            const { data } = await GetSectionWiseQuestionApi(params);
            if (data?.status) {
                setQuestions(data?.responses);
                setIsAllowSectionRepeat(data?.section?.allow_repeat);
            }
            setIsQuestionLoading(false);
        } catch (error) {
            setIsQuestionLoading(false);
        }
    }

    useEffect(() => {
        getSectionWiseQuestion();
    }, [selectedSection]);

    const handleNextStep = () => {
        function getNextSection(sectionId) {
            // Find the index of the current section by section_id
            const index = sectionList.findIndex(section => section.section_id === sectionId);

            // Iterate over the remaining sections after the found index
            for (let i = index + 1; i < sectionList.length; i++) {
                if (sectionList[i].is_show === 1) {
                    return sectionList[i];
                }
            }

            // Return null if no matching section is found
            return null;
        }
        let nextSection = getNextSection(selectedSection?.section_id);
        if (nextSection) {
            setSelectedSection(nextSection);
        }
        if (sectionList?.length == nextSection?.section_order) {
            setIsNext(false);
        } else {
            setIsNext(true);
        }
    }

    const handleSubmit = async () => {
        try {
            setIsLoadingSubmit(true);
            let params = { organizer_id: id };
            const { data } = await SubmitOrganizerApi(params);
            if (data?.status) {
                const { data } = await SetActiveTaskApi(params);
                if (data?.status) {
                    navigate('/organizer');
                }
            }
            setIsLoadingSubmit(false);
        } catch (error) {
            setSelectedSection(sectionList.find(obj => obj.section_id === Object.keys(error?.response?.data?.data)[0]) || null);
            toast.error('Please complete this section first.');
            setIsLoadingSubmit(false);
        }
    }

    const handleSectionChange = (section) => {
        setSelectedSection(section);
        if (sectionList?.length == section?.section_order) {
            setIsNext(false);
        } else {
            setIsNext(true);
        }
    }

    const handleRepeatSection = async () => {
        try {
            setIsLoadingRepeat(true);
            let params = {
                org_section_id: selectedSection?.section_id,
                organizer_id: id
            };
            const { data } = await RepeatSectionApi(params);
            if (data?.status) {
                getOrganizerDetails(false);
            }
            setIsLoadingRepeat(false);
        } catch (error) {
            setIsLoadingRepeat(false);
        }
    }

    const handleRemoveSection = async () => {
        try {
            setIsLoadingRemoveSection(true);
            let params = {
                org_section_id: selectedSection?.section_id,
            };
            const { data } = await RemoveSectionApi(params);
            if (data?.status) {
                getOrganizerDetails(false);
            }
            setIsLoadingRemoveSection(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoadingRemoveSection(false);
        }
    }

    return (
        <div>
            <div className='grid xl:grid-cols-4 gap-4'>
                <div className='border-2 rounded-lg pt-3 '>
                    <div>
                        <p className='text-primary text-center font-semibold border-b-2 pb-3 capitalize line-clamp-1'>{organizer?.organizer_name_slug || '--'}</p>
                        <div className='px-6 pt-8 h-[calc(100vh-160px)] overflow-y-auto'>
                            {isLoading ? (
                                <div className='flex flex-col items-center gap-2 justify-center h-full'>
                                    <Spin />
                                    <p className='text-secondaryLight1'>Loading...</p>
                                </div>
                            ) : (
                                <Timeline
                                    className="custom-timeline"
                                    items={sectionList?.filter(item => item?.is_show == 1)?.map((item, index) => (
                                        {
                                            dot: <Avatar className={`cursor-pointer border-2 font-bold ${item?.question_count == item?.filled_answers_count ? 'border-green-500 text-green-500' : `${selectedSection?.section_id == item?.section_id ? 'border-primary font-semibold bg-transparent text-primary duration-300' : 'border-secondaryLight1 text-secondaryLight1'}`}`} style={{ backgroundColor: 'var(--background-color)' }}>{index + 1}</Avatar>,
                                            children: <div className='ms-1 mb-2 hover:bg-secondaryLight p-1 ps-2 rounded-md cursor-pointer' onClick={() => handleSectionChange(item)}>
                                                <p className='text-secondaryLight1'>Question {item?.filled_answers_count}/{item?.question_count}</p>
                                                <p className='font-bold'>{item?.section_name}</p>
                                            </div>,
                                        }
                                    ))}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {selectedSection &&
                    <div className='xl:col-span-3'>
                        {isQuestionLoading ? (
                            <div className='flex flex-col items-center gap-2 mt-36'>
                                <Spin />
                                <p className='text-secondaryLight1'>Loading...</p>
                            </div>
                        ) : (
                            <div>
                                <div className='overflow-y-auto'>
                                    <div className='flex flex-wrap justify-between items-center mt-4'>
                                        <div className='flex items-center gap-3'>
                                            <div className='bg-secondaryLight h-8 w-8 rounded-full p-1 cursor-pointer'>
                                                <img src='/assets/icons/question-mark-circle.svg' alt='icon' />
                                            </div>
                                            <div className='bg-secondaryLight h-8 w-8 rounded-full p-1 cursor-pointer'>
                                                <img src='/assets/icons/ellipsis-vertical.svg' alt='icon' />
                                            </div>
                                            <div className='bg-secondaryLight h-8 w-8 rounded-full p-1 cursor-pointer'>
                                                <img src='/assets/icons/x-mark.svg' alt='icon' />
                                            </div>
                                        </div>
                                        {/* <div className='flex items-center gap-5'>
                                            <div className='flex items-center gap-2 cursor-pointer'>
                                                <img src='/assets/icons/compare.svg' alt='icon' />
                                                <p className='font-medium'>Compare Answers</p>
                                            </div>
                                            <div className='flex items-center gap-2 cursor-pointer'>
                                                <img src='/assets/icons/documents.svg' alt='icon' />
                                                <p className='font-medium'>Attached Docs</p>
                                            </div>
                                        </div> */}
                                    </div>

                                    {/* <div className='flex items-center gap-2 bg-primaryLight p-2 rounded-md mt-9 text-sm'>
                                        <img src='/assets/icons/exclamation-circle.svg' className='size-5' alt='icon' />
                                        <p>Tax Tribe has sealed this organizer - you can no longer edit it. Please reach out to them if you need assistance.</p>
                                    </div> */}

                                    <div className='w-4/5'>
                                        <QuestionContainer questions={questions} reloadSections={getOrganizerDetails} reloadQuestions={getSectionWiseQuestion} />

                                        {isAllowSectionRepeat === 1 &&
                                            <div className='flex gap-5 items-center'>
                                                <LoadableButton
                                                    className='capitalize font-semibold text-primary rounded-md mt-8'
                                                    img={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                                                    </svg>}
                                                    type='button'
                                                    lable='Repeat Section'
                                                    loadingLable='Loading section...'
                                                    onClick={handleRepeatSection}
                                                    isLoading={isLoadingRepeat}
                                                />
                                                <LoadableButton
                                                    className='capitalize font-semibold text-primary rounded-md mt-8'
                                                    img={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                    </svg>}
                                                    type='button'
                                                    lable='Remove Section'
                                                    loadingLable='Removing section...'
                                                    onClick={handleRemoveSection}
                                                    isLoading={isLoadingRemoveSection}
                                                />
                                            </div>
                                        }
                                        <div className='question-container w-4/5'>
                                            <div className='mt-8 mb-6'>
                                                <div className='flex items-center gap-4'>
                                                    {isNext && <Button size='large' type='primary' className='py-6 px-12 uppercase font-semibold' onClick={handleNextStep}>
                                                        NEXT
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                                        </svg>
                                                    </Button>}
                                                    {!(organizer?.is_submit) &&
                                                        <LoadableButton
                                                            className='uppercase font-semibold text-primary border border-primary rounded-md px-12 py-3'
                                                            type='button'
                                                            lable='Submit'
                                                            loadingLable='Submitting...'
                                                            onClick={handleSubmit}
                                                            isLoading={isLoadingSubmit}
                                                        />}
                                                    <div className='flex items-center gap-1'>
                                                        <div className='size-[22px]'>{isCompleted ? <img className='size-6' src='/assets/icons/check-circle.svg' alt='icon' /> : <img className='size-[22px]' src='/assets/icons/x-circle.svg' alt='icon' />}</div>
                                                        <p className='text-secondaryLight1'>{`All answers are ${isCompleted ? '' : 'not'} saved.`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </div>
        </div>
    )
}
