import React, { useEffect, useState } from 'react';
import { Input, Dropdown, Drawer, Breadcrumb, Form, Upload, Image } from 'antd';
import { Cookies } from "react-cookie";
import { encryptData } from '../../../services/EncryptData.js';
import { getSocket } from '../../../services/Socket.js';
import SkeletonTable from '../../../components/table/SkeletonTable';
import { GetChildFolderListApi, GetFolderListApi, UploadFileFolderApi } from '../../../api/request/documents/documents.js';
import moment from 'moment';
import columns from '../../../columns/organizer/taxtribe-upload.js';
import ModalComponent from '../../modals/ModalComponent';
import LoadableButton from '../../buttons/LoadableButton';
import toast from 'react-hot-toast';

export default function MyFile({ data }) {

    const { question } = data;
    const cookies = new Cookies();
    const { token } = cookies.getAll();
    const socket = getSocket();
    const [uploadFileForm] = Form.useForm();
    const [isDeviceUpload, setIsDeviceUpload] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isTaxTribeOpen, setIsTaxTribeOpen] = useState(false);
    const [isFolderListLoading, setIsFolderListLoading] = useState(false);
    const [folderListForTaxtribe, setFolderListForTaxtribe] = useState([]);
    const [taxTribeBreadcrumbItems, setTaxTribeBreadcrumbItems] = useState([]);
    const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
    const [uploadFileFormValue, setUploadFileFormValue] = useState({ files: [] });
    const [isUploadFileLoading, setIsUploadFileLoading] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const isReviewedChecked = data?.reviewed_checked;
    const disabledStyle = { cursor: "not-allowed", opacity: 0.6 };
    const handleTaxTribe = () => {
        getFolderList();
        setIsTaxTribeOpen(true);
        setTaxTribeBreadcrumbItems([]);
    }

    const handleDevice = () => {
        getFolderList();
        setIsDeviceUpload(true);
        setIsTaxTribeOpen(true);
        setTaxTribeBreadcrumbItems([]);
    }

    const items = [
        {
            key: '1',
            label: <div onClick={handleTaxTribe}>Upload from TaxTribe</div>
        },
        {
            key: '2',
            label: <div onClick={handleDevice}>Upload from Device</div>
        }
    ];

    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        uploadFileForm.setFieldsValue({ files: newFileList });
        if (newFileList?.length == 0) {
            uploadFileForm.setFieldsValue({ files: [] });
        }
    }

    const UploadButton = (
        <button type="button" className='flex flex-col items-center gap-2'>
            <div className="size-7"><img src="/assets/icons/upload.svg" alt="icon" /></div>
            Upload
        </button>
    );

    const sendFile = (file) => {
        const answer = encryptData({ token: token, id: data?.id, response_text: file?.original_name, document_id: file?.id });
        socket.emit('updateQuestion', answer);
        setUploadedFile(file);
        setIsTaxTribeOpen(false);
    }

    const closeTaxTribe = () => {
        setIsTaxTribeOpen(false);
        setFolderListForTaxtribe([]);
    }

    const getFolderList = async () => {
        try {
            setIsFolderListLoading(true);
            const { data } = await GetFolderListApi();
            if (data?.status) {
                setFolderListForTaxtribe(
                    data?.data?.map((item) => {
                        return {
                            ...item,
                            name: (<div className='flex items-center gap-3 cursor-pointer' onClick={() => openFolder(item)}><img src='/assets/icons/folder-red.svg' alt='icon' className='size-5' /><p>{item?.original_name}</p></div>),
                            addedDate: item?.created_at ? moment(item?.created_at).format('lll') : '--',
                        };
                    })
                );
            }
            setIsFolderListLoading(false);
        } catch (error) {
            setIsFolderListLoading(false);
        }
    }

    const getIcons = (file) => {
        const fileExtension = file.split('.').pop().toLowerCase() || null;

        switch (fileExtension) {
            case 'pdf':
                return <img src='/assets/icons/pdf-template.svg' alt='icon' className='size-7' />;
            case 'jpeg':
            case 'jpg':
            case 'png':
                return <img src='/assets/icons/img-template.svg' alt='icon' className='size-7' />;
            case 'doc':
            case 'docx':
                return <img src='/assets/icons/doc-template.svg' alt='icon' className='size-7' />;
            case 'csv':
                return <img src='/assets/icons/csv-template.svg' alt='icon' className='size-7' />;
            case 'xlsx':
                return <img src='/assets/icons/excel-template.svg' alt='icon' className='w-6 h-7' />;
            default:
                return <img src='/assets/icons/folder-red.svg' alt='icon' className='size-5' />;
        }
    }

    useEffect(() => {
        if (!isTaxTribeOpen) {
            setIsDeviceUpload(false);
        }
    }, [isTaxTribeOpen]);

    const openFolder = async (folder) => {
        try {
            setIsFolderListLoading(true);
            setCurrentFolder(folder);
            const { data } = await GetChildFolderListApi({ folder_id: folder?.id });
            setTaxTribeBreadcrumbItems(
                data?.breadcrumb?.map((item) => {
                    return {
                        title: (<p className='cursor-pointer' onClick={() => openFolder({ id: item?.id })} >{item?.name}</p>)
                    }
                })
            );
            setFolderListForTaxtribe(
                data?.data?.map((item) => {
                    return {
                        ...item,
                        name: (<div className='flex items-center gap-3 cursor-pointer' onClick={() => item?.type == 'folder' ? openFolder(item) : sendFile(item)}>{getIcons(item?.original_name)}<p>{item?.original_name}</p></div>),
                        addedDate: item?.created_at ? moment(item?.created_at).format('lll') : '--',
                    };
                })
            );
            setIsFolderListLoading(false);
        } catch (error) {
            setIsFolderListLoading(false);
        }
    }

    const uploadFileHandler = async (values) => {
        const extractOriginFileObjs = (fileArray) => {
            return fileArray.map(file => file.originFileObj);
        };
        try {
            if (!currentFolder) {
                toast.error('Please select folder first to upload.');
                return;
            }
            setIsUploadFileLoading(true);
            let params = {
                files: extractOriginFileObjs(values?.files),
                parent_id: currentFolder?.id
            }
            let apiHeader = { headers: { Accept: "application/json", "Content-Type": "multipart/form-data" } };
            const { data } = await UploadFileFolderApi(params, apiHeader);
            if (data?.status) {
                toast.success(data?.message);
                openFolder(currentFolder);
                uploadFileForm.resetFields();
                setIsOpenUploadModal(false);
            }
            setIsUploadFileLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsUploadFileLoading(false);
        }
    }

    return (
        <div>
            {data?.is_show == 1 && <div className='mt-6'>
                <p className='mb-2'>{question?.field_label}</p>
                <Dropdown menu={{ items }} trigger='click'>
                    <Input
                        className='py-3 ps-4'
                        disabled={isReviewedChecked}
                        style={isReviewedChecked ? disabledStyle : {}}
                        size='large'
                        readOnly
                        onClick={(e) => e.preventDefault()}
                        value={uploadedFile?.original_name || data?.response_text || 'Choose file'}
                        prefix={
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#D32E2E" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                            </svg>
                        }
                    />
                </Dropdown>
            </div >}

            <Drawer title='Select file' open={isTaxTribeOpen} onClose={closeTaxTribe} width={820} maskClosable={false}>
                <div className='relative h-full'>
                    <div className='border-2 rounded-lg pb-3'>
                        {
                            taxTribeBreadcrumbItems?.length > 0 &&
                            <Breadcrumb
                                className='my-3 mx-4'
                                separator={
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="var(--text-color)" className="size-4 mt-1">
                                        <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
                                    </svg>
                                }
                                items={taxTribeBreadcrumbItems}
                            />
                        }
                        <div className={`${taxTribeBreadcrumbItems?.length > 0 ? 'table-radius-0' : ''}`}>
                            <SkeletonTable columns={columns} data={folderListForTaxtribe} isLoading={isFolderListLoading} />
                        </div>
                    </div>
                    {(isDeviceUpload && currentFolder) &&
                        <button size='large' onClick={() => setIsOpenUploadModal(true)} className='flex gap-3 px-4 py-2 rounded-lg uppercase font-semibold text-primary border border-primary absolute bottom-0 right-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#D32E2E" className="size-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                            </svg>
                            Upload
                        </button>
                    }
                </div>
            </Drawer>

            {/* --- UPload file modal --- */}
            <ModalComponent isOpen={isOpenUploadModal} setIsOpen={setIsOpenUploadModal} title='Upload a new file' width={600}>
                <Form className='w-full' autoComplete="off" form={uploadFileForm} initialValues={uploadFileFormValue} onFinish={uploadFileHandler}>
                    <div>
                        <Form.Item name="files" rules={[{ required: true, message: 'Please upload file.' }]}>
                            <Upload
                                beforeUpload={() => false}
                                multiple
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                                {fileList.length >= 1 ? null : UploadButton}
                            </Upload>
                        </Form.Item>
                        {previewImage && (
                            <Image
                                wrapperStyle={{ display: 'none' }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                            />
                        )}
                    </div>
                    <LoadableButton
                        className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 mt-4 rounded-lg themeHover duration-500'
                        type="submit"
                        lable='Upload'
                        isLoading={isUploadFileLoading}
                        loadingLable='Uploading file...'
                    />
                </Form>
            </ModalComponent>
        </div >
    )
}
