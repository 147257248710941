// src/components/GoogleLogin.js
import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

const GoogleLoginComponent = () => {
    const handleSuccess = (response) => {
        console.log('Login Success:', response);
        const decoded = jwtDecode(response.credential);
        console.log(decoded);
        // You can exchange the response token with your backend to get user details
    };

    const handleFailure = (error) => {
        console.error('Login Failed:', error);
    };

    return (
        <GoogleOAuthProvider  clientId="540073057360-g4dmcsj20donkht3gpbr4upcvkf20u74.apps.googleusercontent.com" >
            <div className='me-4'>
                <GoogleLogin
                    allowed_parent_origin=""
                    onSuccess={handleSuccess}
                    onError={handleFailure}
                    theme='outline'
                    useOneTap
                    redirectUri="https://taxtribe-cabinet.staging9dot.com"
                    cookiePolicy={'single_host_origin'}

                >
                    <button type="button" className='bg-primaryLight rounded-lg flex items-center justify-center py-2 sm:w-1/3 w-full me-5'> <img src='/assets/icons/Google.svg' alt='icon' className='me-2' />Google</button>

                </GoogleLogin>
            </div>
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginComponent;
