import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input } from 'antd';
import { LoginApi } from '../../api/request/auth.js';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import LoadableButton from '../../components/buttons/LoadableButton.jsx';
import GoogleLoginComponent from './components/GoogleLoginComponent.jsx';
import AppleLoginComponent from './components/AppleLoginComponent.jsx';
import { IpProvider } from '../../services/IpProvider.js';

const Login = () => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState({ email: "", password: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [ipAddress, setIpAddress] = useState('');

    const getIpAddress = async () => {
        try {
            const { data } = await IpProvider();
            setIpAddress(data?.ip);
        } catch (error) { }
    }

    useEffect(() => {
        getIpAddress();
    }, [])

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            let params = {
                ...values,
                login_type: 'api',
                ip_address: ipAddress
            }
            const { data } = await LoginApi(params);
            if (data?.status) {
                toast.success(data?.message);
                form.resetFields();
                localStorage.setItem('email', values?.email);
                if (data?.is_2fa) {
                    navigate('/authentication');
                } else {
                    navigate('/otp');
                }
            }
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className='sm:min-h-screen flex justify-center items-center '>
                <Form autoComplete='off' className='sm:w-3/5 w-full' form={form} initialValues={initialValues} onFinish={handleSubmit}>

                    <p className='font-bold sm:text-5xl text-3xl text-secondary mb-5'>Login</p>
                    <p className="text-lg text-secondaryLight2 mb-8">Login to access your taxtribe account</p>

                    <Form.Item name="email" className='mb-0 mt-5' rules={[
                        { required: true, message: 'Please enter your email!', },
                        { type: 'email', message: 'Please enter valid email!', },
                    ]}>
                        <Input placeholder='Email address' size='large' />
                    </Form.Item>
                    <Form.Item name="password" className='mb-0 mt-5' rules={[{ required: true, message: 'Please enter your password!', },]}>
                        <Input.Password placeholder='Password' size='large' />
                    </Form.Item>
                    <div className='flex justify-between items-center mt-5'>
                        <Form.Item name="remember" valuePropName="checked" className='mb-0'>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <p className='text-primary underline cursor-pointer font-semibold' onClick={() => navigate('/forgot_password')}>Forgot Password?</p>
                    </div>
                    <LoadableButton
                        type="submit"
                        className='bg-primary text-white uppercase text-base font-semibold w-full py-2 rounded-lg mt-7 mb-5'
                        lable='Login'
                        loadingLable='Logging in...'
                        isLoading={isLoading}
                    />
                    <p className='text-center font-semibold mb-5'>Don't have an account? <span className='underline text-primary cursor-pointer' onClick={() => navigate('/register')}> Sign up </span></p>
                    <div className="flex items-center mb-5">
                        <hr className="flex-grow" />
                        <span className="mx-2 text-secondaryLight2">OR CONTINUE WITH</span>
                        <hr className="flex-grow" />
                    </div>
                    <div className='flex justify-center items-center  text-secondary '>

                        <GoogleLoginComponent />
                        <AppleLoginComponent />
                    </div>
                </Form>
            </div>
        </>
    )
}

export default Login