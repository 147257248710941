import React, { useEffect, useState } from 'react';
import RadialBarChart from '../../components/charts/RadialBarChart.jsx';
import SkeletonTable from '../../components/table/SkeletonTable.jsx';
import columns from '../../columns/dashboard/billingDetails.js';
import { GetClientDashboardApi } from '../../api/request/dashboard/dashboard.js';
import { Spin } from 'antd';
import moment from 'moment';
import Stripe from '../../services/payments/Stripe.jsx';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

export default function Dashboard() {

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();
  const secondaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--secondaryLight1'))?.trim();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [commonData, setCommonData] = useState([]);
  const [organizerData, setOrganizerData] = useState([]);
  const [activeOrganizer, setActiveOrganizer] = useState(0);
  const [activeButton, setActiveButton] = useState(null);
  const [billingData, setBillingData] = useState([]);

  const [dashboardCards, setDashboardCards] = useState([
    { title: 'Tickets', total: '00', icon: '/assets/icons/ticket.svg', bgColor: '#FBEAEA' },
    { title: 'Active Organizer', total: '00', icon: '/assets/icons/active-organizer.svg', bgColor: '#DDF6E8' },
    { title: 'Signatures', total: '00', icon: '/assets/icons/signature-apply.svg', bgColor: '#E9E7FD' },
  ]);

  const getDashboard = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetClientDashboardApi();
      if (data?.status) {
        const updatedTotals = [data?.data?.total_tickets, data?.data?.active_organizations, data?.data?.apply_for_sign];
        setDashboardCards(dashboardCards?.map((card, index) => ({ ...card, total: updatedTotals[index] })));

        setData(data?.data);
        setCommonData(data?.data?.site_setting);
        setOrganizerData(data?.data?.organization);
        setBillingData(data?.data?.billing_details?.map((item) => {
          return {
            ...item,
            name: data?.data?.name,
            status: <div className={`${item?.status == 'active' ? 'text-green-500' : 'text-red-500'} font-semibold uppercase`}>{item?.status}</div>,
            amount: `$ ${Number(item?.amount)?.toFixed(2)}`,
            created_at: item?.created_at ? moment(item?.created_at).format('ll') : '--',
            action: <Elements stripe={stripePromise}><Stripe data={item} /></Elements>
          };
        }))
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getDashboard();
  }, []);

  const handleIncrease = () => {
    if (activeOrganizer < organizerData?.length - 1) {
      setActiveOrganizer(activeOrganizer + 1);
    }
  }

  const handleDecrease = () => {
    if (activeOrganizer > 0) {
      setActiveOrganizer(activeOrganizer - 1);
    }
  }

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center flex-col mt-[20%]">
          <Spin size="large" />
          <p className="primary text-center mt-2">Loading...</p>
        </div>
      ) : (
        <div className='grid sm:grid-cols-4 gap-4'>
          <div className='sm:col-span-3'>
            <div className='grid sm:grid-cols-3 gap-5'>
              {dashboardCards?.map((item, index) =>
                <div key={index} className='border-2 rounded-2xl p-4'>
                  <div className='flex justify-between items-center'>
                    <div>
                      <p className='font-medium'>{item?.title}</p>
                      <p className='font-semibold text-2xl mt-2'>{item?.total}</p>
                    </div>
                    <div className={`bg-[${item?.bgColor}] rounded-xl size-14 flex items-center justify-center`}>
                      <img src={item?.icon} alt='icon' />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className='grid sm:grid-cols-3 gap-5 mt-5'>

              <div className='border-2 rounded-2xl flex flex-col items-center space-y-5'>
                <div className='sm:pt-5 px-4'>
                  <p className='font-semibold leading-8 text-xl'>Securely upload your documents on our user-friendly <span className='text-primary'>Tax Filing</span> platform.</p>
                </div>
                <div>
                  <img src="/assets/images/dashBoardDoc.png" alt="img" />
                </div>
              </div>

              {organizerData?.length > 0 ? (
                <div className='border-2 rounded-2xl sm:col-span-2 flex flex-col sm:flex-row justify-between sm:pt-0 pt-2'>
                  <div className='flex flex-col justify-around px-5'>
                    <h1 className='font-bold text-xl'>Organizer Status</h1>
                    <div className='text-secondaryLight2'>
                      <h2 className='text-primary font-semibold text-lg mb-3 capitalize'>{organizerData[activeOrganizer]?.organizer_name}</h2>
                      <h4 className='mb-2'>{organizerData[activeOrganizer]?.date_created}</h4>
                      <p>{organizerData[activeOrganizer]?.description}</p>
                    </div>
                    <div>
                      <h4 className='font-bold mb-1'>Status</h4>
                      <p className='capitalize'><span className='text-xl text-primary me-2'>●</span> {organizerData[activeOrganizer]?.organizer_status}</p>
                    </div>
                    <div className='flex items-center space-x-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" onClick={handleDecrease} fill="none" viewBox="0 0 24 24" stroke-width="2" stroke={`${activeOrganizer === 0 ? secondaryColor : 'currentColor'}`} className='size-5 cursor-pointer'>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" onClick={handleIncrease} fill="none" viewBox="0 0 24 24" stroke-width="2" stroke={`${organizerData?.length - 1 === activeOrganizer ? secondaryColor : 'currentColor'}`} className='size-5 cursor-pointer'>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                      </svg>
                    </div>
                  </div>
                  <div className='flex justify-center items-center'>
                    <RadialBarChart
                      values={[organizerData[activeOrganizer]?.completion_percentage || 0]}
                      colors={[primaryColor]}
                      centered={false}
                      value={true}
                      name={false}
                    />
                  </div>
                </div>
              ) : (
                <div className='border-2 rounded-2xl sm:col-span-2 sm:pt-0 pt-2'>
                  <div className='flex flex-col items-center justify-center w-full h-full'>
                    <img src='assets/images/organizer-warehouse.svg' className='h-3/5' />
                    <p className='text-secondaryLight1 mt-3'>No organizer found</p>
                  </div>
                </div>
              )}
            </div>

            <div className='border-2 rounded-2xl mt-5 p-6'>
              <h3 className="text-xl font-bold mb-4">Waiting for action</h3>
              <div>
                {data?.waiting_for_action?.length > 0 ? (
                  <div>
                    {data?.waiting_for_action?.map((item, index) => (
                      <div key={index} className="border border-primary rounded-md flex justify-between items-center p-4">
                        <div>
                          <h4 className="text-base font-medium">{item?.title}</h4>
                          <p className="text-sm text-secondaryLight2">{item?.description}</p>
                        </div>
                        <button className="text-primary underline font-semibold text-base">
                          Respond
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className='text-secondaryLight1'>No data</p>
                )}
              </div>
            </div>

            <div className='border-2 rounded-2xl my-5'>
              <h1 className='my-5 mx-5 font-semibold text-xl'>Billing Details</h1>
              <div className='my-6 table-radius-0'>
                <SkeletonTable columns={columns} data={billingData} />
              </div>
            </div>
          </div>
          <div className='border-2 rounded-2xl w-full space-y-5'>
            <div className='px-4 py-4'>
              <div className='w-full border rounded-xl h-48'></div>
            </div>
            <div>
              <div className='bg-secondaryLight py-2 ps-4'>Links</div>
              <div className='px-4 py-4'>
                <div className='w-full sm:space-x-1.5 text-sm flex sm:flex-row flex-col sm:space-y-0 space-y-1'>
                  <button className={`border rounded-md py-2 px-2 ${activeButton === 'uploadDocuments' ? 'border-black text-black' : 'border-secondaryLight1 text-secondaryLight1'}`}
                    onClick={() => setActiveButton('uploadDocuments')}
                  >
                    Upload documents
                  </button>
                  <button className={`border rounded-md py-2 px-2 ${activeButton === 'uploadFolder' ? 'border-black text-black' : 'border-secondaryLight1 text-secondaryLight1'}`}
                    onClick={() => setActiveButton('uploadFolder')}
                  >
                    Upload folder
                  </button>
                  <button className={`border rounded-md py-2 px-2 ${activeButton === 'newChat' ? 'border-black text-black' : 'border-secondaryLight1 text-secondaryLight1'}`}
                    onClick={() => setActiveButton('newChat')}
                  >
                    New chat
                  </button>
                </div>
                <div className='w-full sm:space-x-1.5 mt-1.5 text-sm flex sm:flex-row flex-col sm:space-y-0 space-y-1'>
                  <button className={`border rounded-md py-2 px-2 ${activeButton === 'bookAppointment' ? 'border-black text-black' : 'border-secondaryLight1 text-secondaryLight1'}`}
                    onClick={() => setActiveButton('bookAppointment')}
                  >
                    Book appointment
                  </button>
                  <button className={`border rounded-md py-2 px-2 ${activeButton === 'makePrepayment' ? 'border-black text-black' : 'border-secondaryLight1 text-secondaryLight1'}`}
                    onClick={() => setActiveButton('makePrepayment')}
                  >
                    Make prepayment
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className='bg-secondaryLight py-2 ps-4'>Balance</div>
              <div className='flex justify-between px-4 py-4 w-full'>
                <div className='w-full '>
                  <p className='text-sm text-secondaryLight1 mb-1'>Credits Available</p>
                  <p className='text-lg'>CA$0.00</p>
                </div>
                <div className='w-full'>
                  <p className='text-sm text-secondaryLight1 mb-1'>Outstanding Balance</p>
                  <p className='text-lg'>CA$0.00</p>
                </div>
              </div>
            </div>
            <div>
              <div className='bg-secondaryLight py-2 ps-4'>Contact Info</div>
              <div className='px-4 py-4'>
                <div className='border-b flex items-center gap-3 py-2 px-'>
                  <div className='h-5 border-r-2 pe-2'>
                    <img src='/assets/icons/phone.svg' alt='icon' />
                  </div>
                  <div>
                    <p className='text-secondaryLight1'>Phone</p>
                    <p className='text-sm mt-1'>{commonData?.phone}</p>
                  </div>
                </div>
                <div className='border-b flex items-center gap-3 py-2 px-'>
                  <div className='h-5 border-r-2 pe-2'>
                    <img src='/assets/icons/email.svg' alt='icon' />
                  </div>
                  <div>
                    <p className='text-secondaryLight1'>Email</p>
                    <p className='text-sm mt-1'>{commonData?.email}</p>
                  </div>
                </div>
                <div className='border-b flex items-center gap-3 py-2 px-'>
                  <div className='h-5 border-r-2 pe-2'>
                    <img src='/assets/icons/location.svg' alt='icon' />
                  </div>
                  <div>
                    <p className='text-secondaryLight1'>Address</p>
                    <p className='text-sm mt-1'>{commonData?.address}</p>
                  </div>
                </div>
                <div className='border-b flex items-center gap-3 py-2 px-'>
                  <div className='h-5 border-r-2 pe-2'>
                    <img src='/assets/icons/web.svg' alt='icon' />
                  </div>
                  <div>
                    <p className='text-secondaryLight1'>Website</p>
                    <a className='text-sm mt-1 line-clamp-1' target='_blank' href={commonData?.website_url}>{commonData?.website_url}</a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='bg-secondaryLight py-2 ps-4'>Social Links</div>
              <div className='flex gap-3 py-4 px-4'>
                <a href={commonData?.facebook_url} target='_blank' className='bg-[#3B599933] h-8 w-8 rounded-full flex justify-center items-center'>
                  <img src="/assets/icons/facebook.svg" alt="icon" className='h-5 w-5' />
                </a>
                <a href={commonData?.instagram_url} target='_blank' className='bg-gradient-to-b from-[rgba(219,0,172,0.2)] to-[rgba(255,203,0,0.2)] h-8 w-8 rounded-full flex justify-center items-center'>
                  <img src="/assets/icons/instagram.svg" alt="icon" className='h-5 w-5' />
                </a>
                <a href={commonData?.linkedin_url} target='_blank' className='bg-[#0B69C733] h-8 w-8 rounded-full flex justify-center items-center'>
                  <img src="/assets/icons/linkedIn.svg" alt="icon" className='h-5 w-5' />
                </a>
              </div>
              <div className='px-4 py-4'>
                <div className='bg-secondaryLight rounded-xl text-center content-center px-2'>
                  <h4 className='font-bold text-lg pt-2'>Download mobile app</h4>
                  <p className='text-sm mt-2'>Download mobile app to upload and sign documents, send messages, pay bills and more-all in one place</p>
                  <div className='flex justify-center gap-3 flex-wrap pt-4'>
                    <button className='h-10'><img src="/assets/icons/playstore-btn.svg" alt="btn" className='object-contain' /></button>
                    <button className='h-10'><img src="/assets/icons/appstore-btn.svg" alt="btn" className='object-contain' /></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
    </div>
  )
}
