import React from 'react';

export default function Documents({ active }) {

    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();

    return (
        <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4226 1.57153H6.35714C4.06746 1.57153 2.92262 1.57153 2.21131 2.28285C1.5 2.99416 1.5 4.139 1.5 6.42868V18.5715C1.5 20.8612 1.5 22.006 2.21131 22.7174C2.92262 23.4287 4.06746 23.4287 6.35714 23.4287H13.6429C15.9325 23.4287 17.0774 23.4287 17.7887 22.7174C18.5 22.006 18.5 20.8612 18.5 18.5715V8.64891C18.5 8.15257 18.5 7.9044 18.4076 7.68125C18.3151 7.4581 18.1397 7.28261 17.7887 6.93165L13.1399 2.28285C12.7889 1.93188 12.6134 1.7564 12.3903 1.66397C12.1671 1.57153 11.919 1.57153 11.4226 1.57153Z" stroke={`${active ? primaryColor : 'currentColor'}`} stroke-width="2" />
            <path d="M6.35742 13.7144L13.6431 13.7144" stroke={`${active ? primaryColor : 'currentColor'}`} stroke-width="2" stroke-linecap="round" />
            <path d="M6.35742 18.5715L11.2146 18.5715" stroke={`${active ? primaryColor : 'currentColor'}`} stroke-width="2" stroke-linecap="round" />
            <path d="M11.2148 1.57153V6.42868C11.2148 7.57351 11.2148 8.14593 11.5705 8.50159C11.9262 8.85725 12.4986 8.85725 13.6434 8.85725H18.5006" stroke={`${active ? primaryColor : 'currentColor'}`} stroke-width="2" />
        </svg>
    )
}
