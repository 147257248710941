import React from 'react';
import AppleLogin from 'react-apple-login';

const AppleLoginComponent = () => {
    const handleSuccess = (response) => {
        console.log('Login Success:', response);
    };

    const handleFailure = (error) => {
        console.error('Login Failed:', error);
    };

    return (
        <div className="me-4">
            <AppleLogin
                clientId="com.your.client.id" // Services ID (Client ID)
                redirectURI="https://your-redirect-uri.com/callback" // redirect URI
                usePopup={true} // Set to true to use popup
                onSuccess={handleSuccess}
                onError={handleFailure}
                render={(props) => (
                    <button type="button" onClick={props.onClick} className='border rounded-sm flex items-center justify-center py-2 px-5 w-full'><img src='/assets/icons/apple.svg' alt='icon' className='me-2' />Sign in with apple</button>
                )}
            />
        </div>
    );
};

export default AppleLoginComponent;
