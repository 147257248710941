import React from 'react';
import { Input } from 'antd';
import { Cookies } from "react-cookie";
import { encryptData } from '../../../services/EncryptData.js';
import { getSocket } from '../../../services/Socket.js';

export default function MyEmail({ data }) {

    const { question } = data;
    const cookies = new Cookies();
    const { token } = cookies.getAll();
    const socket = getSocket();
    const isReviewedChecked = data?.reviewed_checked;
    const disabledStyle = { cursor: "not-allowed", opacity: 0.6 };

    const onBlur = (event) => {
        const answer = encryptData({ token: token, id: data?.id, response_text: (event?.target?.value)?.toLowerCase() });
        socket.emit('updateQuestion', answer);
    }

    return (
        <div>
            {data?.is_show == 1 && <div className='mt-6'>
                <p className='mb-2'>{question?.field_label}</p>
                <Input
                    className='py-3 ps-4'
                    disabled={isReviewedChecked}
                    style={isReviewedChecked ? disabledStyle : {}}
                    size='large'
                    type='email'
                    placeholder='Email Address'
                    defaultValue={data?.response_text}
                    onBlur={onBlur}
                />
            </div>}
        </div>
    )
}