import { Skeleton, Switch } from 'antd';
import React, { useState } from 'react';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import { NotificationSettingApi } from '../../../api/request/settings/settings.js';
import toast from 'react-hot-toast';

export default function Notification({ isLoading, settingsData, setSettingsData }) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoadingNotification, setIsLoadingNotification] = useState(false);

    const notificationHandler = async () => {
        try {
            setIsLoadingNotification(true);
            const { data } = await NotificationSettingApi({ is_notify: (!settingsData?.is_notify)?.toString() });
            if (data?.status) {
                settingsData.is_notify = !settingsData?.is_notify;
                setSettingsData(settingsData);
                toast.success(data?.message);
                setIsModalOpen(false);
            }
            setIsLoadingNotification(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoadingNotification(false);
        }
    }

    return (
        <div>
            <div className='border-2 rounded-lg p-4'>
                <p className='font-semibold text-lg border-b-2 pb-3'>Notification</p>
                {isLoading ? (
                    <div className='flex items-center gap-4 mt-5'>
                        <Skeleton.Button active={true} size='small' />
                        <Skeleton.Button active={true} size='small' block={true} />
                    </div>
                ) : (
                    <div className='flex items-center gap-4 mt-5'>
                        <Switch
                            onClick={() => setIsModalOpen(true)}
                            value={settingsData?.is_notify}
                        />
                        <p>Stay updated with notificatins.</p>
                    </div>
                )}
            </div>

            {/* -------- Disable notification modal -------- */}
            <ConfirmationModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                message={`Are you sure you want to ${settingsData?.is_notify ? 'disable' : 'enable'} notifications?`}
                onConfirm={notificationHandler}
                isLoading={isLoadingNotification}
                loadingLabel={`${settingsData?.is_notify ? 'Disabling' : 'Enabling'} notifications...`}
            />
        </div>
    )
}