import { useSelector } from 'react-redux';
import AppLayout from '../components/global/layout/Layout.jsx';
import { Navigate } from 'react-router-dom';
import AuthLayout from '../components/global/layout/AuthLayout.jsx';

const ProtectedRoute = ({ type, children, title, image }) => {
    const { loggedIn } = useSelector(state => state.user);

    if (type === "private") {
        if (loggedIn) {
            return <AppLayout title={title} >{children}</AppLayout>
        } else {
            return <Navigate to="/login" replace />
        }
    }
    if (type === "public") {
        return children;
    }
    if (type === "auth") {
        if (!loggedIn) {
            return <AuthLayout image={image} title={title}>{children}</AuthLayout>;
        } else {
            return <Navigate to="/dashboard" replace />;
        }
    }
    return;
}

export default ProtectedRoute;