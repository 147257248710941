import React from 'react';
import { DatePicker } from 'antd';
import { Cookies } from "react-cookie";
import { encryptData } from '../../../services/EncryptData.js';
import { getSocket } from '../../../services/Socket.js';
import dayjs from 'dayjs';


export default function MyDatePicker({ data }) {

    const { question } = data;
    const cookies = new Cookies();
    const { token } = cookies.getAll();
    const socket = getSocket();
    const isReviewedChecked = data?.reviewed_checked;
    const disabledStyle = { cursor: "not-allowed" };

    const onChange = (date, dateString) => {
        const answer = encryptData({ token: token, id: data?.id, response_text: dateString || '' });
        socket.emit('updateQuestion', answer);
    }

    return (
        <div>
            {data?.is_show == 1 && <div className='mt-6'>
                <p className='mb-2'>{question?.field_label}</p>
                <DatePicker
                    size='large'
                    disabled={isReviewedChecked}
                    style={isReviewedChecked ? disabledStyle : {}}
                    className='w-full py-3 ps-4'
                    format='YYYY-MM-DD'
                    placeholder='YYYY-MM-DD'
                    defaultValue={data?.response_text ? dayjs(data?.response_text) : null}
                    onChange={onChange}
                />
            </div>}
        </div>
    )
}
