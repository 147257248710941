import React, { useState } from 'react';
import { Form, Input } from 'antd';
import LoadableButton from '../../components/buttons/LoadableButton.jsx'
import { useNavigate } from 'react-router-dom';
import { LoginOtpVerificationApi, ResendOtpApi } from '../../api/request/auth';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setUserDetails, setloggedIn } from "../../store/Slices/userSlice.js";
import { setCookies } from "../../services/Cookies.js";

const Otp = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState({ otp: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [isOtpLoading, setIsOtpLoading] = useState(false);

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            let params = {
                email: localStorage.getItem('email'),
                ...values
            }
            const { data } = await LoginOtpVerificationApi(params);
            if (data?.status) {
                dispatch(setloggedIn(true));
                dispatch(setUserDetails(data?.data));
                setCookies('token', data?.token);
                toast.success(data?.message);
                form.resetFields();
                localStorage.removeItem('email');
                navigate('/dashboard');
            }
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoading(false);
        }
    }

    const resendOtp = async () => {
        try {
            setIsOtpLoading(true);
            let params = {
                email: localStorage.getItem('email'),
            }
            const { data } = await ResendOtpApi(params);
            if (data?.status) {
                toast.success(data?.message);
            }
            setIsOtpLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsOtpLoading(false);
        }
    }

    return (
        <>
            <div className='sm:min-h-screen flex justify-center items-center '>
                <Form autoComplete='off' className='sm:w-3/5 w-full' form={form} initialValues={initialValues} onFinish={handleSubmit} >
                    <div className='text-primary font-semibold text-lg flex items-center mb-5 cursor-pointer' onClick={() => navigate(-1)}><img src="assets/icons/Back.svg" alt="icon" />Back</div>
                    <p className='font-bold sm:text-5xl text-3xl text-secondary mb-5'>OTP Verification</p>
                    <p className="text-lg text-secondaryLight2 mb-8">Enter the verification code we just sent to your <br /> {localStorage?.getItem('email') ? localStorage?.getItem('email') : 'registered email'}</p>
                    <Form.Item className='mb-0' name="otp" rules={[{ required: true, message: '' }]} >
                        <Input.OTP size='large' style={{ width: '100%' }} />
                    </Form.Item>
                    <p className='font-semibold mb-5 mt-7'>Don’t receive a code?<span className='text-primary cursor-pointer' onClick={resendOtp}> {isOtpLoading ? 'Sending otp...' : 'Resend'}</span></p>
                    <LoadableButton
                        className='bg-primary text-white uppercase text-base font-semibold w-full py-2 rounded-lg mt-7 mb-5'
                        type='submit'
                        lable='Verify'
                        loadingLable='Verifying...'
                        isLoading={isLoading}
                    />
                </Form>
            </div>
        </>
    )
}

export default Otp