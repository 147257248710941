import Dashboard from "../../../../assets/Dashboard.jsx";
import Organizer from "../../../../assets/Organizer.jsx";
import Documents from "../../../../assets/Documents.jsx";
import Communication from "../../../../assets/Communication.jsx";
import Referrals from "../../../../assets/Referrals.jsx";
import Settings from "../../../../assets/Settings.jsx";

export const SidebarData = [
    {
        title: 'Dashboard',
        icon: <Dashboard />,
        url: '/dashboard',
        activeUrl: ['/dashboard']
    },
    {
        title: 'Organizer',
        icon: <Organizer />,
        url: '/organizer',
        activeUrl: ['/organizer', '/start_organizer']
    },
    {
        title: 'Documents',
        icon: <Documents />,
        url: '/documents',
        activeUrl: ['/documents', '/documents_trash']
    },
    {
        title: 'Communication',
        icon: <Communication />,
        url: '/communication',
        activeUrl: ['/communication']
    },
    {
        title: 'Referrals',
        icon: <Referrals />,
        url: '/referrals',
        activeUrl: ['/referrals']
    },
    {
        title: 'Settings',
        icon: <Settings />,
        url: '/settings',
        activeUrl: ['/settings']
    },
];