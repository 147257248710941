import React, { useEffect, useState } from 'react';
import columns from '../../../columns/documents/trash-doc-folder.js';
import SkeletonTable from '../../../components/table/SkeletonTable';
import { Dropdown, Input } from 'antd';
import { GetTrashFolderListApi, OpenTrashFolderApi, PermanentDeleteFileFolderApi, RestorefilefolderApi } from '../../../api/request/documents/documents.js';
import moment from 'moment';
import ConfirmationModal from '../../../components/modals/ConfirmationModal.jsx';
import toast from 'react-hot-toast';

export default function Trash() {

    const [selectedItem, setSelectedItem] = useState(null);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false);
    const [isRestoreLoading, setIsRestoreLoading] = useState(false);
    const items = [
        {
            label: <div className='flex items-center cursor-pointer gap-3' onClick={() => setIsRestoreModalOpen(true)}><img src='/assets/icons/restore.svg' className='size-4' alt='icon' /><p>Restore</p></div>,
            key: '1',
        },
        {
            label: <div className='flex items-center cursor-pointer gap-3 text-primary' onClick={() => setIsDeleteModalOpen(true)}><img src='/assets/icons/trash-red.svg' className='size-4' alt='icon' /><p>Delete Permanently</p></div>,
            key: '2',
        },
    ];

    const getIcons = (type) => {
        switch (type) {
            case 'application/pdf':
                return <img src='/assets/icons/pdf-template.svg' alt='icon' className='size-7' />;
            case 'image/jpeg':
                return <img src='/assets/icons/img-template.svg' alt='icon' className='size-7' />;
            case 'image/jpg':
                return <img src='/assets/icons/img-template.svg' alt='icon' className='size-7' />;
            case 'image/png':
                return <img src='/assets/icons/img-template.svg' alt='icon' className='size-7' />;
            case 'application/msword':
                return <img src='/assets/icons/doc-template.svg' alt='icon' className='size-7' />;
            case 'text/csv':
                return <img src='/assets/icons/csv-template.svg' alt='icon' className='size-7' />;
            case 'application/vnd.ms-excel':
                return <img src='/assets/icons/excel-template.svg' alt='icon' className='size-7' />;
            case null:
                return <img src='/assets/icons/folder-red.svg' alt='icon' className='size-5' />;
            default:
                return
        }
    }

    const getChildFolderList = async (folder) => {
        try {
            setIsLoading(true);
            const { data } = await OpenTrashFolderApi({ folder_id: folder?.id });
            if (data?.status) {
                setData(
                    data?.data?.map((item) => {
                        return {
                            ...item,
                            name: (<div className='flex items-center gap-3 cursor-pointer' onClick={() => item?.type == 'folder' ? getChildFolderList(item) : null}>{getIcons(item?.file_type)}<p>{item?.original_name}</p></div>),
                            deletedDate: item?.deleted_at ? moment(item?.deleted_at).format('lll') : '--',
                            actions: (
                                <Dropdown
                                    menu={{ items }}
                                    trigger={['click']}
                                    className='cursor-pointer size-6 min-h-6 min-w-6'
                                    onClick={() => setSelectedItem(item)}
                                >
                                    <img src='/assets/icons/ellipsis-vertical.svg' alt='icon' />
                                </Dropdown>
                            ),
                        };
                    })
                );
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getTrashedItemsList = async () => {
        try {
            setIsLoading(true);
            const { data } = await GetTrashFolderListApi();
            if (data?.status) {
                setData(
                    data?.data?.map((item) => {
                        return {
                            ...item,
                            name: (<div className='flex items-center gap-3 cursor-pointer' onClick={() => item?.type == 'folder' ? getChildFolderList(item) : null}>{getIcons(item?.file_type)}<p>{item?.original_name}</p></div>),
                            deletedDate: item?.deleted_at ? moment(item?.deleted_at).format('lll') : '--',
                            actions: (
                                <Dropdown
                                    menu={{ items }}
                                    trigger={['click']}
                                    className='cursor-pointer size-6 min-h-6 min-w-6'
                                    onClick={() => setSelectedItem(item)}
                                >
                                    <img src='/assets/icons/ellipsis-vertical.svg' alt='icon' />
                                </Dropdown>
                            ),
                        };
                    })
                );
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getTrashedItemsList();
    }, [])

    const deleteItemHandler = async () => {
        try {
            setIsDeleteLoading(true);
            const { data } = await PermanentDeleteFileFolderApi({ id: selectedItem?.id });
            if (data?.status) {
                setData((prevData) => prevData.filter((item) => item.id !== selectedItem?.id));
                setSelectedItem('');
                setIsDeleteModalOpen(false);
                toast.success(data?.message);
            }
            setIsDeleteLoading(false);
        } catch (error) {
            setIsDeleteLoading(false);
        }
    }

    const restoreItemHandler = async () => {
        try {
            setIsRestoreLoading(true);
            const { data } = await RestorefilefolderApi({ id: selectedItem?.id });
            if (data?.status) {
                setData((prevData) => prevData.filter((item) => item.id !== selectedItem?.id));
                setSelectedItem('');
                setIsRestoreModalOpen(false);
                toast.success(data?.message);
            }
            setIsRestoreLoading(false);
        } catch (error) {
            setIsRestoreLoading(false);
        }
    }

    return (
        <div>
            {
                data?.length !== 0 ? (
                    <div>
                        <div>
                            <Input size="large" placeholder="Search here..." className='xl:w-80' prefix={
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#B3B3B3" class="size-6">
                                    <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                                </svg>}
                            />
                        </div>

                        <div className='border-2 rounded-lg mt-5'>
                            <div className='mb-5'>
                                <SkeletonTable columns={columns} data={data} isLoading={isLoading} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='h-[calc(100vh-106px)] flex items-center justify-center'>
                        <div>
                            <img src='assets/images/empty-trash.png' className='w-96 mx-auto' alt='image' />
                            <p className='text-center font-bold text-3xl mt-4'>Nothing in trash</p>
                            <p className='text-center text-secondaryLight2 mt-3 md:w-4/5 mx-auto'>Move items you don’t need to trash. Items in trash will be deleted forever after 30 days.</p>
                        </div>
                    </div>
                )}

            {/* --- Delete confirmation modal --- */}
            <ConfirmationModal
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                message={`Are you sure you want to permanently delete this ${selectedItem?.type == 'folder' ? 'folder' : 'file'}?`}
                subMessage='You will not able to revert this changes again.'
                onConfirm={deleteItemHandler}
                isLoading={isDeleteLoading}
                loadingLabel={`Deleting ${selectedItem?.type == 'folder' ? 'folder' : 'file'}...`}
            />

            {/* --- Delete confirmation modal --- */}
            <ConfirmationModal
                isOpen={isRestoreModalOpen}
                setIsOpen={setIsRestoreModalOpen}
                message={`Are you sure you want to restore this ${selectedItem?.type == 'folder' ? 'folder' : 'file'}?`}
                onConfirm={restoreItemHandler}
                isLoading={isRestoreLoading}
                loadingLabel={`Restoring ${selectedItem?.type == 'folder' ? 'folder' : 'file'}...`}
            />
        </div>
    )
}