import axios from "axios";

export const IpProvider = async () => {
    return await axios.get(process.env.REACT_APP_IP)
        .then((response) => {
            return (response);
        })
        .catch((error) => {
            console.error('Error fetching the IP address:', error);
            return '';
        });
}