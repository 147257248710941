import React, { useState } from 'react';
import LoadableButton from '../../components/buttons/LoadableButton';
import { CreatePaymentSessionApi } from '../../api/request/payments/payments.js';
import { useStripe } from '@stripe/react-stripe-js';

export default function Stripe({ data }) {

    const stripe = useStripe();
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setIsLoadingPayment(true);
            let params = {
                line_items: [
                    {
                        name: "Taxtribe License",
                        unit_amount: 1000,
                        quantity: 1
                    },
                    {
                        name: "Taxtribe Add-ons",
                        unit_amount: 5000,
                        quantity: 2
                    },
                    {
                        name: "Taxtribe Support",
                        unit_amount: 1500,
                        quantity: 3
                    }
                ],
                success_url: process.env.REACT_APP_STRIPE_SUCCESS_URL,
                cancel_url: process.env.REACT_APP_STRIPE_CANCEL_URL
            };

            const { data } = await CreatePaymentSessionApi(params);

            // Redirect to Stripe Checkout
            const result = await stripe.redirectToCheckout({
                sessionId: data.id,
            });

            if (result.error) {
                console.error(result.error.message);
            }
        } catch (error) {
            setIsLoadingPayment(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <LoadableButton
                className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 rounded-lg themeHover duration-500'
                type="submit"
                lable='Pay'
                isLoading={isLoadingPayment}
                loadingLable='Paying...'
            />
        </form>
    )
}