import React from 'react';

export default function Referrals({ active }) {

    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();

    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.0996 6.62646V1.52772H23.1984" fill={`${active ? primaryColor : 'currentColor'}`} />
            <path d="M19.9432 3.21159C23.0034 5.38622 25 8.95997 25 13C25 19.6274 19.6274 25 13 25" stroke={`${active ? primaryColor : 'currentColor'}`} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
            <path d="M7.49725 19.7032V24.5032H2.69727" fill={`${active ? primaryColor : 'currentColor'}`} />
            <path d="M6.05672 22.7883C2.99653 20.6137 1 17.0399 1 12.9999C1 6.37247 6.37255 0.999918 13 0.999918" stroke={`${active ? primaryColor : 'currentColor'}`} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
            <path d="M16.0462 8.66861C16.0461 9.29457 15.8613 9.90646 15.5151 10.4269C15.1689 10.9473 14.6769 11.3529 14.1013 11.5924C13.5257 11.8319 12.8923 11.8945 12.2813 11.7723C11.6702 11.6502 11.109 11.3487 10.6685 10.906C10.228 10.4634 9.928 9.89944 9.80649 9.2855C9.68498 8.67156 9.74738 8.0352 9.98582 7.45689C10.2243 6.87859 10.628 6.3843 11.146 6.03655C11.664 5.68879 12.2731 5.50317 12.8961 5.50317C13.3098 5.5032 13.7194 5.5851 14.1016 5.74419C14.4838 5.90329 14.8311 6.13646 15.1236 6.4304C15.4161 6.72433 15.6482 7.07328 15.8064 7.45731C15.9647 7.84135 16.0462 8.25295 16.0462 8.66861ZM12.8961 12.7702C12.004 12.7691 11.1216 12.9549 10.305 13.3157C9.48839 13.6764 8.7556 14.2042 8.15338 14.8654C7.09032 16.0291 7.3507 17.8804 8.6892 18.709C9.95479 19.4898 11.4109 19.9031 12.8961 19.9031C14.3812 19.9031 15.8374 19.4898 17.103 18.709C18.4415 17.8804 18.7018 16.0291 17.6388 14.8654C17.0366 14.2042 16.3038 13.6764 15.4872 13.3157C14.6706 12.9549 13.7881 12.7691 12.8961 12.7702Z" fill={`${active ? primaryColor : 'currentColor'}`} />
        </svg>
    )
}