import React from 'react';

export default function DownloadApp() {
    return (
        <div>
            <div className='border-2 rounded-lg p-4'>
                <p className='font-semibold text-lg border-b-2 pb-3'>Download Mobile App</p>
                <div className='mt-4 flex flex-wrap gap-5'>
                    <button className='h-10'><img src="/assets/icons/playstore-btn.svg" alt="btn" className='object-contain' /></button>
                    <button className='h-10'><img src="/assets/icons/appstore-btn.svg" alt="btn" className='object-contain' /></button>
                </div>
            </div>
        </div>
    )
}