import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input, Select } from 'antd';
import { GetCountryListApi } from '../../api/request/countryDetail';
import toast from 'react-hot-toast';
import LoadableButton from '../../components/buttons/LoadableButton';


const { Option } = Select;

const SignUp = () => {
    const [countryData, setCountryData] = useState([]);
    const getCountryList = async () => {
        try {
            const { data } = await GetCountryListApi();
            if (data?.status) {
                setCountryData(data?.data);
            }
        }
        catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);

            }
        }
    }

    useEffect(() => {
        getCountryList();
    }, [])

    return (
        <>
            <div className='sm:min-h-screen flex justify-center items-center '>
                <Form autoComplete='off' className='sm:w-3/5 w-full'>
                    <p className='font-bold sm:text-5xl text-3xl text-secondary mb-5'>Sign up</p>
                    <p className="text-lg text-secondaryLight2 mb-8">Create your taxtribe account</p>

                    <Form.Item name="name" className='mb-0' rules={[{ required: true, message: 'Please enter your name!', },]}>
                        <Input placeholder='Name' size='large' />
                    </Form.Item>

                    <div className="flex sm:flex-row flex-col sm:gap-4">
                        <Form.Item name="country_code" className='mb-0 mt-5 sm:min-w-[28%]' rules={[{ required: true, message: 'Please select country', },]}>
                            <Select placeholder='SelectCountry' size="large"  >
                                {countryData?.map((countryCode, index) => (
                                    <Option key={index} value={countryCode?.phonecode}>
                                        <div className="flex items-center space-x-3">
                                            <div className='rounded-full bg-secondaryLight w-9 h-9 flex justify-center items-center'>
                                                <img src={countryCode?.image} alt="flag" className="w-7 h-7 object-contain rounded-full" />
                                            </div>
                                            <span className='text-base'>+ {countryCode?.phonecode}</span>
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="mobile_no" className='mb-0 mt-5 w-full' rules={[
                            { required: true, message: 'Please input  mobile number!', },
                            {
                                pattern: /^\d{10}$/,
                                message: 'Mobile number should be 10 digits!',
                            },
                        ]}>
                            <Input size="large" type='Number' placeholder="Mobile No." />
                        </Form.Item>
                    </div>

                    <Form.Item name="tnc" valuePropName="checked" className='mb-0 mt-3' rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Please accept the terms and conditions.!')), }]}>
                        <Checkbox>I agree all the<span className='text-primary '> Terms </span>and<span className='text-primary'> Privacy Policies</span></Checkbox>
                    </Form.Item>
                    <LoadableButton
                        className='bg-primary text-white uppercase text-base font-semibold w-full py-2 rounded-lg mt-7 mb-5'
                        type='submit'
                        lable='Countinue'
                        loadingLable='Createing Account...'
                        isLoading={false}
                    />
                </Form>
            </div>

        </>
    )
}

export default SignUp