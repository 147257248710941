import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {
        loggedIn: false,
        userDetails: {
            id: '',
            parent_id: '',
            first_name: '',
            last_name: '',
            image: '',
            name: '',
            email: '',
            email_verification: '',
            login_type: '',
            email_otp: '',
            folder_name: '',
            referral_code: '',
            user_referral_code: '',
            role: '',
            mobile_no: '',
            country_code: '',
            last_login: '',
            is_2fa_enabled: '',
            recovery_code: '',
            google2fa_secret: '',
            is_login: '',
            deleted_at: '',
            created_at: '',
            updated_at: ''
        },
    },
    reducers: {
        setloggedIn: (state, action) => {
            state.loggedIn = action.payload;
        },
        setUserDetails: (state, action) => {
            state.userDetails = action.payload;
        },
    },
})

export const { setloggedIn, setUserDetails } = userSlice.actions;

export default userSlice.reducer;